import React, { useState, FC, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  DialogActions,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_CLIENT_URL, ONEMAP_BASE_URL } from 'constants/url';
import { combineAddress } from 'utils';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingButtonIndicator from 'components/LoadingButtonIndicator';
import { grey } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Props {
  clients: ClientDetailsModel;
  setClients: React.Dispatch<React.SetStateAction<ClientDetailsModel>>;
  handleSnackbar: (variant: 'success' | 'error', message: string) => void;
  openForm: boolean;
  handleClose(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: 'auto',
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  textfield: {
    marginBottom: theme.spacing(1)
  },
  rightPadding: {
    paddingRight: theme.spacing(1)
  },
  contentGrid: {
    padding: theme.spacing(2)
  },
  redColor: {
    color: theme.palette.error.main
  },
  flagImg: {
    height: 13,
    width: 20,
    marginRight: 10
  }
}));

const LocationForm: FC<Props> = props => {
  const classes = useStyles();
  const { clients, setClients, handleSnackbar, handleClose } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSameAddress, setIsSameAddress] = useState<boolean>(false);
  const [clientId, setClientId] = useState<number>(0);
  const [billingAddress, setBillingAddress] = useState<string>('');
  const [billingFloorNo, setBillingFloorNo] = useState<string>('');
  const [billingUnitNo, setBillingUnitNo] = useState<string>('');
  const [billingPostal, setBillingPostal] = useState<string>('');
  const [billingPostalValue, setBillingPostalValue] = useState<Select>({ id: 0, name: '' });
  const [addresses, setAddresses] = useState<ServiceAddressModel[]>([]);
  const [serviceAddressPostal, setServiceAddressPostal] = useState<string[]>(['']);
  const [serviceAddressPostalValue, setServiceAddressPostalValue] = useState<Select[]>([{ id: 0, name: '' }]);
  const [deletedServiceAddresses, setDeletedServiceAddresses] = useState<number[]>([]);
  const [addressOptions, setAddressOptions] = useState<Select[]>([]);

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any[]>([{ postalCode: '', address: '' }]);

  useEffect(() => {
    if (!clients) {
      return;
    }
    const { id, billingAddress, billingFloorNo, billingUnitNo, billingPostal, ServiceAddresses } = clients!;

    setClientId(id);
    setBillingAddress(billingAddress);
    setBillingFloorNo(billingFloorNo || '');
    setBillingUnitNo(billingUnitNo || '');
    setBillingPostal(billingPostal);
    setAddresses(
      ServiceAddresses.map(value => {
        return { ...value };
      })
    );

    // setAddresses(tempData);
    setBillingPostalValue({ id: Number(ServiceAddresses[0].postalCode), name: ServiceAddresses[0].address });
    setIsSameAddress(billingPostal === ServiceAddresses[0].postalCode || false);

    let temp: string[] = [];
    let tempValue: Select[] = [];
    let tempError: any[] = [...errorMessage];
    // eslint-disable-next-line array-callback-return
    ServiceAddresses.map(value => {
      const splitAddress = value.address.split(', ');
      const getAddress = splitAddress.filter(address => !address.includes(value.floorNo! || value.unitNo!) && !address.includes(value.postalCode));
      temp.push(value.postalCode);
      tempValue.push({ id: Number(value.postalCode), name: getAddress.join(', ') });
      tempError.push({ postalCode: '', address: '' });
    });
    setServiceAddressPostal(temp);
    setServiceAddressPostalValue(tempValue);
    setErrorMessage(tempError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  const getAddress = async (postalCode: string) => {
    try {
      setIsLoading(true);
      setAddressOptions([]);
      const getQueryParams = () => {
        const params = new URLSearchParams();
        params.append('returnGeom', 'N');
        params.append('getAddrDetails', 'Y');
        params.append('searchVal', postalCode);
        return params.toString();
      };

      const url = `${ONEMAP_BASE_URL}?${getQueryParams()}`;
      const response = await fetch(url);
      const { results } = await response.json();
      let fetchAddress: Select[] = [];

      if (results && results.length > 0) {
        // eslint-disable-next-line array-callback-return
        results.map((value: any) => {
          if (!isNaN(value['POSTAL'])) {
            return fetchAddress.push({
              id: Number(value['POSTAL']),
              name: value['BUILDING'].includes('NIL')
                ? `${value['BLK_NO']} ${value['ROAD_NAME']}, ${value['POSTAL']}`
                : `${value['BUILDING']}, ${value['BLK_NO']} ${value['ROAD_NAME']}, ${value['POSTAL']}`,
              value: value['BUILDING'].includes('NIL')
                ? `${value['BLK_NO']} ${value['ROAD_NAME']}`
                : `${value['BUILDING']}, ${value['BLK_NO']} ${value['ROAD_NAME']}`,
              tempValue: value['POSTAL']
            });
          }
        });
      }

      setAddressOptions(fetchAddress);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleRemoveSubServiceAddress = (i: number): React.MouseEventHandler => () => {
    const currentServiceAddress = [...addresses];
    const currentServiceAddressPostal = [...serviceAddressPostal];
    const currentServiceAddressPostalValue = [...serviceAddressPostalValue];
    const currentDeletedServiceAddress = [...deletedServiceAddresses];
    currentDeletedServiceAddress.push(addresses[i].id!);
    currentServiceAddress.splice(i, 1);
    currentServiceAddressPostal.splice(i, 1);
    currentServiceAddressPostalValue.splice(i, 1);
    setAddresses(currentServiceAddress);
    setServiceAddressPostal(currentServiceAddressPostal);
    setServiceAddressPostalValue(currentServiceAddressPostalValue);
    setDeletedServiceAddresses(currentDeletedServiceAddress);
  };

  const handleAddSubServiceAddress = () => {
    const currentServiceAddress = [...addresses];
    const currentErrorMessage = [...errorMessage];
    currentServiceAddress.push({
      id: 0,
      contactPerson: currentServiceAddress[0].contactPerson,
      countryCode: currentServiceAddress[0].countryCode,
      contactNumber: currentServiceAddress[0].contactNumber,
      secondaryContactPerson: currentServiceAddress[0].secondaryContactPerson,
      secondaryContactNumber: currentServiceAddress[0].secondaryContactNumber,
      country: currentServiceAddress[0].country,
      address: '',
      floorNo: '',
      unitNo: '',
      postalCode: '',
      clientId: currentServiceAddress[0].clientId
    });
    currentErrorMessage.push({ postalCode: '', address: '' });

    setAddresses(currentServiceAddress);
    setErrorMessage(currentErrorMessage);
  };

  const hanldeCheckBoxChange = () => {
    setIsSameAddress(!isSameAddress);
    setBillingAddress(!isSameAddress ? addresses[0].address : '');
    setBillingFloorNo(!isSameAddress ? addresses[0].floorNo! : '');
    setBillingUnitNo(!isSameAddress ? addresses[0].unitNo! : '');
    setBillingPostal(!isSameAddress ? addresses[0].postalCode : '');
    setBillingPostalValue(!isSameAddress ? { id: Number(addresses[0].postalCode), name: addresses[0].postalCode } : { id: 0, name: '' });
    setAddressOptions([]);
    setErrorMessage(prev => {
      prev[0].postalCode = '';
      prev[0].address = '';
      return [...prev];
    });
  };

  const handleFreeTextServicePostal = async (value: any, index: number) => {
    if (value) {
      if (!isNaN(value)) {
        if (value.length > 3) {
          await getAddress(value);
        }

        if (isSameAddress && index === 0) {
          setBillingPostal(value);
        }
        setAddresses(prev => {
          prev[index].postalCode = value;
          return [...prev];
        });
        setServiceAddressPostal(prev => {
          prev[index] = value;
          return [...prev];
        });
      }
    } else {
      setAddresses(prev => {
        prev[index].postalCode = '';
        return [...prev];
      });
      setServiceAddressPostal(prev => {
        prev[index] = '';
        return [...prev];
      });
      if (isSameAddress && index === 0) {
        setBillingPostal('');
      }
    }
  };

  const handleChangeServicePostal = (value: any, index: number) => {
    const currentServiceAddress = addresses;
    if (value) {
      const fullAddress = combineAddress(
        value.value,
        currentServiceAddress[index].floorNo || '',
        currentServiceAddress[index].unitNo || '',
        value.tempValue
      );
      currentServiceAddress[index].address = fullAddress;
      currentServiceAddress[index].postalCode = String(value.tempValue);

      if (isSameAddress && index === 0) {
        setBillingPostal(String(value.tempValue));
        setBillingAddress(fullAddress);
      }

      setAddresses(currentServiceAddress);
      setServiceAddressPostal(prev => {
        prev[index] = String(value.tempValue);
        return [...prev];
      });
      setServiceAddressPostalValue(prev => {
        prev[index] = { id: value.id, name: value.value };
        return [...prev];
      });
      setErrorMessage(prev => {
        prev[index + 1].postalCode = '';
        prev[index + 1].address = '';
        return [...prev];
      });
    }
  };

  const handleChangeServiceFloorNo = (value: string, index: number) => {
    const { unitNo, postalCode } = addresses[index];
    const fullAddress = combineAddress(serviceAddressPostalValue[index].name, value, unitNo || '', postalCode);

    if (isSameAddress && index === 0) {
      setBillingFloorNo(value);
      setBillingAddress(fullAddress);
    }

    setAddresses(prev => {
      prev[index].address = fullAddress;
      prev[index].floorNo = value;
      return [...prev];
    });
  };

  const handleChangeServiceUnitNo = (value: string, index: number) => {
    const { floorNo, postalCode } = addresses[index];
    const fullAddress = combineAddress(serviceAddressPostalValue[index].name, floorNo || '', value, postalCode);

    if (isSameAddress && index === 0) {
      setBillingUnitNo(value);
      setBillingAddress(fullAddress);
    }

    setAddresses(prev => {
      prev[index].address = fullAddress;
      prev[index].unitNo = value;
      return [...prev];
    });
  };

  const handleChangeServiceAddress = (value: string, index: number) => {
    setAddresses(prev => {
      prev[index].address = value;
      return [...prev];
    });
  };

  const handleFreeTextBillingPostal = async (value: any) => {
    if (value) {
      if (!isNaN(value)) {
        setBillingPostal(value);
        if (value.length > 3) {
          await getAddress(value);
        }
      }
    } else {
      setBillingPostal('');
      setAddressOptions([]);
    }
  };

  const handleChangeBillingPostal = (value: any) => {
    if (value) {
      setBillingPostal(String(value.tempValue));
      setBillingPostalValue({ id: value.id, name: value.value });
      const fullAddress = combineAddress(value.value, billingFloorNo, billingUnitNo, value.tempValue);
      setBillingAddress(fullAddress);
      setErrorMessage(prev => {
        prev[0].postalCode = '';
        prev[0].address = '';
        return [...prev];
      });
    } else {
      setBillingPostal('');
      setBillingPostalValue({ id: 0, name: '' });
      setBillingAddress('');
    }
  };

  const handleChangeBillingFloor = (value: string) => {
    setBillingFloorNo(value);
    const fullAddress = combineAddress(billingPostalValue.name, value, billingUnitNo, billingPostal);
    setBillingAddress(fullAddress);
  };

  const handleChangeBillingUnit = (value: string) => {
    setBillingUnitNo(value);
    const fullAddress = combineAddress(billingPostalValue.name, billingFloorNo, value, billingPostal);
    setBillingAddress(fullAddress);
  };

  const handleOnBlur = (field: string, value: string, index: number) => {
    const currentErrorMessage = [...errorMessage];
    if (!value) {
      if (field === 'postalCode') {
        currentErrorMessage[index].postalCode = 'Please enter postal code';
      } else {
        currentErrorMessage[index].address = 'Please enter address';
      }
      setIsError(true);
    } else {
      if (field === 'postalCode') {
        currentErrorMessage[index].postalCode = '';
      } else {
        currentErrorMessage[index].address = '';
      }
      setIsError(false);
    }
    setErrorMessage(currentErrorMessage);
  };

  const handleSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setIsLoading(true);
    if (isError) {
      setIsLoading(false);
      return;
    }
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      const { data } = await axios.put(
        `${GET_EDIT_CLIENT_URL(clientId)}`,
        {
          isEditServiceAddress: true,
          billingAddress,
          billingFloorNo,
          billingUnitNo,
          billingPostal,
          ServiceAddresses: addresses,
          deletedServiceAddresses
        },
        { cancelToken: cancelTokenSource.token }
      );
      setClients({
        ...clients,
        billingAddress,
        billingPostal,
        ServiceAddresses: data.ServiceAddresses
      });

      handleSnackbar('success', 'Successfully edit address');
      handleCancel();
    } catch (err) {
      console.log(err);
      handleSnackbar('error', 'Failed edit address');
    }
    setIsLoading(false);
  };

  const renderServiceAddresses = () => {
    return (
      addresses &&
      addresses.length > 0 &&
      addresses.map((value, index) => {
        return (
          <>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Typography variant='h6'>
                  Service Address {index + 1}
                  {index === 0 && <span className={classes.redColor}>*</span>}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id={`postalCode-${index + 1}`}
                      options={addressOptions}
                      getOptionLabel={options => options.name}
                      value={serviceAddressPostalValue[index]}
                      inputValue={serviceAddressPostal[index] || ''}
                      onInputChange={(_, value, reason) => {
                        if (reason === 'input') {
                          handleFreeTextServicePostal(value, index);
                        }
                        if (reason === 'clear') {
                          setServiceAddressPostal(prev => {
                            prev[index] = '';
                            return [...prev];
                          });
                        }
                      }}
                      onChange={(_, value) => {
                        handleChangeServicePostal(value, index);
                      }}
                      autoHighlight
                      freeSolo
                      loading={isLoading}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required
                          margin='dense'
                          className={classes.textfield}
                          id='postalCode'
                          label={`Postal Code ${index + 1}`}
                          variant='outlined'
                          error={errorMessage[index + 1].postalCode !== ''}
                          helperText={errorMessage[index + 1].postalCode}
                          onBlur={event => handleOnBlur('postalCode', event.target.value, index + 1)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      id='unit'
                      label={`Floor No. ${index + 1}`}
                      value={value.floorNo}
                      onChange={event => handleChangeServiceFloorNo(event.target.value, index)}
                      autoComplete='off'
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      id='unit'
                      label={`Unit No. ${index + 1}`}
                      value={value.unitNo}
                      onChange={event => handleChangeServiceUnitNo(event.target.value, index)}
                      autoComplete='off'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      margin='dense'
                      required
                      fullWidth
                      multiline={true}
                      rows={3}
                      id='address'
                      label={`Address ${index + 1}`}
                      value={value.address}
                      onChange={event => handleChangeServiceAddress(event.target.value, index)}
                      autoComplete='off'
                      error={errorMessage[index + 1].address !== ''}
                      helperText={errorMessage[index + 1].address}
                      onBlur={event => handleOnBlur('address', event.target.value, index + 1)}
                    />
                  </Grid>
                </Grid>

                <Grid container style={{ marginTop: 8 }}>
                  {index !== 0 && (
                    <Grid item xs={6}>
                      <Button onClick={handleRemoveSubServiceAddress(index)} className={classes.redColor} startIcon={<DeleteIcon />}>
                        Remove Address
                      </Button>
                    </Grid>
                  )}
                  {index === addresses.length - 1 && (
                    <Grid item container justify='flex-end' xs={index !== 0 ? 6 : 12}>
                      <Button color='primary' startIcon={<AddIcon />} onClick={handleAddSubServiceAddress}>
                        More Address
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {index !== addresses.length - 1 && <Divider style={{ marginTop: 16, marginBottom: 16 }} />}
          </>
        );
      })
    );
  };

  const renderBillingAddress = () => {
    return (
      <Card variant='outlined' className={classes.card}>
        <CardHeader title={<Typography variant='subtitle2'>Billing Address</Typography>} style={{ backgroundColor: grey[200], height: 35 }} />{' '}
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Typography variant='h6'>
                Billing Address<span className={classes.redColor}>*</span>
              </Typography>
              <FormControlLabel
                control={<Checkbox checked={isSameAddress} color='primary' onChange={hanldeCheckBoxChange} />}
                label={
                  <Typography variant='caption' color='textSecondary'>
                    Mark same as with Service Address 1
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    id='postalCode-0'
                    options={addressOptions}
                    getOptionLabel={options => options.name}
                    value={billingPostalValue}
                    inputValue={billingPostal}
                    onInputChange={(_, value, reason) => {
                      if (reason === 'input') {
                        handleFreeTextBillingPostal(value);
                      }
                    }}
                    onChange={(_, value) => {
                      handleChangeBillingPostal(value);
                    }}
                    autoHighlight
                    freeSolo
                    loading={isLoading}
                    disabled={isSameAddress}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        margin='dense'
                        className={classes.textfield}
                        id='postalCode'
                        label='Postal Code'
                        variant='outlined'
                        error={errorMessage[0].postalCode !== ''}
                        helperText={errorMessage[0].postalCode}
                        onBlur={event => handleOnBlur('postalCode', event.target.value, 0)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    disabled={isSameAddress}
                    id='floor'
                    label='Floor No'
                    value={billingFloorNo}
                    onChange={event => handleChangeBillingFloor(event.target.value)}
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    disabled={isSameAddress}
                    id='unit'
                    label='Unit No'
                    value={billingUnitNo}
                    onChange={event => handleChangeBillingUnit(event.target.value)}
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    required
                    fullWidth
                    multiline={true}
                    rows={3}
                    disabled={isSameAddress}
                    id='address'
                    label='Address'
                    value={billingAddress}
                    error={errorMessage[0].address !== ''}
                    helperText={errorMessage[0].address}
                    onChange={event => setBillingAddress(event.target.value)}
                    autoComplete='off'
                    onBlur={event => handleOnBlur('address', event.target.value, 0)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <Grid container spacing={2} className={classes.contentGrid} alignItems='center'>
      <Card variant='outlined' className={classes.card}>
        <CardHeader title={<Typography variant='subtitle2'>Service Address</Typography>} style={{ backgroundColor: grey[200], height: 35 }} />
        <CardContent>{renderServiceAddresses()}</CardContent>
      </Card>
      {renderBillingAddress()}
      <Grid item container xs={12} md={12} justify='flex-end'>
        <DialogActions>
          <Button variant='contained' disableElevation disabled={isLoading} onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant='contained' disableElevation color='primary' disabled={isLoading} onClick={handleSubmit}>
            Save
            {isLoading && <LoadingButtonIndicator isLoading={isLoading} />}
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default LocationForm;
